@font-face {
    font-family: 'TildaSans';
    font-display: swap;
    src: url('tilda-sans/tilda-sans-bold.eot');
    src: url('tilda-sans/tilda-sans-bold.eot?#iefix') format('embedded-opentype'),
    url('tilda-sans/tilda-sans-bold.woff2') format('woff2'),
    url('tilda-sans/tilda-sans-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TildaSans';
    font-display: swap;
    src: url('tilda-sans/tilda-sans-regular.eot');
    src: url('tilda-sans/tilda-sans-regular.eot?#iefix') format('embedded-opentype'),
    url('tilda-sans/tilda-sans-regular.woff2') format('woff2'),
    url('tilda-sans/tilda-sans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
